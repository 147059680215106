import React, { useEffect, useState } from "react";
import { TypeOfWindowUndefined } from "../../../components/_helpers";
import ReleaseLandingPage from "../[release]";

function AlbumNameLandingPage() {
  if (TypeOfWindowUndefined()) {
    return null;
  }

  const [artistname, setArtistname] = useState("");
  const [albumName, setAlbumName] = useState("");

  useEffect(() => {
    // Extract artistname and albumname from the URL in the browser
    const pathname = window.location.pathname;
    const parts = pathname.split("/").filter((part) => part !== ""); // Remove empty parts

    if (parts.length === 3) {
      // Assuming URL is domain.com/artistname/track/trackname
      setArtistname(parts[0]);
      setAlbumName(parts[2]);
    } else {
      // URL is not in the expected format - 404 page will be shown
    }
  }, []);

  const params = {
    artist: artistname,
    release: albumName,
  };

  return <ReleaseLandingPage params={params} albumPage={true} />;
}

export default AlbumNameLandingPage;
